export const projects = [
  {
    alias: "color",
    repo: "https://github.com/Hbler/color-me-uncertain",
    type: "personal",
    video: "https://www.youtube.com/watch?v=miVxjUJMRPk",
    app: "https://hbler.github.io/color-me-uncertain/",
    img: "./assets/img/color.png",
  },
  {
    alias: "bwlgari",
    repo: "https://github.com/Hbler/ecommerce-joias",
    type: "study",
    video: "https://www.youtube.com/watch?v=aFr6S5ZAdbA",
    app: "https://hbler.github.io/ecommerce-joias/",
    img: "./assets/img/bwlgari.png",
  },
  {
    alias: "genius",
    repo: "https://github.com/Hbler/genius",
    type: "study",
    video: "https://www.youtube.com/watch?v=DnBFcNfDOdw&t=9s",
    app: "https://hbler.github.io/genius/",
    img: "./assets/img/genius.png",
  },
  {
    alias: "oregon",
    repo: "https://github.com/Hbler/oregon-trail",
    type: "study",
    video: "https://www.youtube.com/watch?v=N3BN98QW6Eg",
    app: "https://hbler.github.io/oregon-trail/",
    img: "./assets/img/oregon.png",
  },
  {
    alias: "products",
    repo: "https://github.com/Hbler/products-list",
    type: "study",
    video: "https://www.youtube.com/watch?v=8b-CjqpR9GY",
    app: "https://hbler.github.io/products-list/",
    img: "./assets/img/products.png",
  },
  {
    alias: "music",
    repo: "https://github.com/Hbler/kenzie-music",
    type: "study",
    video: "https://www.youtube.com/watch?v=3F5QOOeweS0",
    app: "https://hbler.github.io/kenzie-music/",
    img: "./assets/img/music.png",
  },
  {
    alias: "blog",
    repo: "https://github.com/Hbler/community-blog",
    type: "study",
    video: "https://www.youtube.com/watch?v=rxKGb9pfPvw",
    app: "https://hbler.github.io/community-blog/",
    img: "./assets/img/blog.png",
  },
  {
    alias: "reproduction",
    repo: "https://github.com/Hbler/streaming-platform",
    type: "study",
    video: "https://www.youtube.com/watch?v=RM9MTVrMlvg",
    app: "https://hbler.github.io/streaming-platform/",
    img: "./assets/img/reproduction.png",
  },
  {
    alias: "food",
    repo: "https://github.com/Hbler/ecommerce-food-w-user",
    type: "team",
    video: "https://www.youtube.com/watch?v=eXtEa0kw-BI",
    app: "https://hbler.github.io/ecommerce-food-w-user/",
    img: "./assets/img/food.png",
  },
  {
    alias: "nu",
    repo: "https://github.com/Hbler/nu-kenzie",
    type: "study",
    video: "https://www.youtube.com/watch?v=-WK3nHlFp44",
    app: "https://react-entrega-s1-nu-kenzie-hbler.vercel.app/",
    img: "./assets/img/nu.png",
  },
  {
    alias: "burguer",
    repo: "https://github.com/Hbler/kenzie-burguer",
    type: "study",
    video: "https://www.youtube.com/watch?v=Os6ZchaPRcE",
    app: "https://react-entrega-s1-hamburgueria-da-kenzie-hbler.vercel.app",
    img: "./assets/img/burguer.png",
  },
  {
    alias: "form",
    repo: "https://github.com/Hbler/react-form-practice",
    type: "study",
    video: "https://www.youtube.com/watch?v=1xRo0t_e6FA",
    app: "https://react-entrega-s2-formulario-de-cadastro-hbler.vercel.app",
    img: "./assets/img/form.png",
  },
  {
    alias: "hub",
    repo: "https://github.com/Hbler/kenzie-hub",
    type: "study",
    video: "https://www.youtube.com/watch?v=-Y1kEKLQXjg",
    app: "https://react-entrega-s2-kenzie-hub-hbler.vercel.app",
    img: "./assets/img/hub.png",
  },
  {
    alias: "redux",
    repo: "https://github.com/Hbler/ecommerc-joias-v2",
    type: "study",
    video: "https://www.youtube.com/watch?v=LHiDQYuiZFk",
    app: "https://react-entrega-s3-kenzieshop-hbler.vercel.app/",
    img: "./assets/img/redux.png",
  },
  {
    alias: "context",
    repo: "https://github.com/Hbler/ecommerce-context-api",
    type: "study",
    video: "https://www.youtube.com/watch?v=zz6GplLKTrU",
    app: " https://react-entrega-s3-kenzishop-com-context-api-hbler.vercel.app",
    img: "./assets/img/context.png",
  },
  {
    alias: "relativi",
    repo: "https://github.com/rel-ativi/relativi-web-app",
    type: "team",
    video: "https://youtu.be/pNa_tcAePw4",
    app: "https://relativi-web-app.vercel.app/",
    img: "./assets/img/relativi.png",
  },
  {
    alias: "relativi-back",
    repo: "https://github.com/rel-ativi/back-end_api_relativi/tree/develop",
    type: "team",
    video: "",
    app: "https://back-end-api-relativi.herokuapp.com/",
    img: "./assets/img/relativi.png",
  },
  {
    alias: "gdc-api",
    repo: "https://github.com/gdc-api/gdc-api-backend",
    type: "team",
    video: "https://youtu.be/z5UXhbTdoh0",
    app: "gdc-api.herokuapp.com/",
    img: "./assets/img/gdc-api.png",
  },
  {
    alias: "pokedex-vue",
    repo: "https://github.com/Hbler/vue-ts-pokedex",
    type: "study",
    video: "https://youtu.be/5hSU2Gz-EiU",
    app: "https://pokedex-hbler.vercel.app/",
    img: "./assets/img/pokedex-vue.png",
  },
  {
    alias: "ng-cash",
    repo: "https://github.com/Hbler/vue-ts-pokedex",
    type: "study",
    video: "https://youtu.be/KMYiDjtqNvw",
    app: "https://youtu.be/KMYiDjtqNvw",
    img: "./assets/img/ng-cash.png",
  },
  {
    alias: "single-spa-ecommerce",
    repo: "https://github.com/Hbler/vue-ts-pokedex",
    type: "study",
    video: "",
    app: "",
    img: "./assets/img/single-spa.png",
  },
];
